import type { ReactNode, Dispatch, SetStateAction } from 'react';
import React, { useCallback } from 'react';

export interface DebugMessage {
    message: string;
    stack?: string;
}
interface UseDebugHook {
    add: (message: DebugMessage) => void;
    clear: () => void;
    messages: DebugMessage[];
}

interface DebugData {
    messages: DebugMessage[];
    setMessages: Dispatch<SetStateAction<DebugMessage[]>>;
}

const DebugContext = React.createContext<DebugData | undefined>(undefined);

export const useDebug = (): UseDebugHook => {
    if (import.meta.env.PROD) {
        return {
            add: () => {
                return;
            },
            clear: () => {
                return;
            },
            messages: [],
        };
    } else {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const context = React.useContext(DebugContext);
        if (!context) {
            throw new Error('Cannot be used outside DebugContext');
        }
        const { messages, setMessages } = context;
        return {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            add: useCallback(
                (message) => {
                    setMessages((current) => [...current, message]);
                },
                [setMessages]
            ),
            // eslint-disable-next-line react-hooks/rules-of-hooks
            clear: useCallback(() => {
                setMessages([]);
            }, [setMessages]),
            messages,
        };
    }
};

interface Props {
    children: ReactNode;
}

export const DebugContextProvider = ({ children }: Props) => {
    const [messages, setMessages] = React.useState<DebugMessage[]>([]);
    return (
        <DebugContext.Provider
            value={{
                messages,
                setMessages,
            }}
        >
            {children}
        </DebugContext.Provider>
    );
};
